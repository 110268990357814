@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap");

* {
  box-sizing: border-box;
}

body {
  line-height: 1.5;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-family: "UnitedSansRegular-Heavy";
}

@font-face {
  font-family: "UnitedSansRegular-Heavy";
  src: local("UnitedSansRegular-Heavy"),
    url("Fonts/UnitedSans/UnitedSansRgHv.otf") format("otf"),
    url("Fonts/UnitedSans/UnitedSansRgHv.ttf") format("ttf"),
    url("Fonts/UnitedSans/UnitedSansRgHv.woff") format("woff");
}

@font-face {
  font-family: "UnitedSansRegular-Medium";
  src: local("UnitedSansRegular-Medium"),
    url("Fonts/UnitedSans/UnitedSansRgMd.otf") format("otf"),
    url("Fonts/UnitedSans/UnitedSansRgMd.ttf") format("ttf"),
    url("Fonts/UnitedSans/UnitedSansRgMd.woff") format("woff");
}

@font-face {
  font-family: MaxEmoji;
  src: url("Fonts/Emoji/Brand-Live-Emoji.otf") format("opentype");
}
