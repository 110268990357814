@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap);
* {
  box-sizing: border-box;
}

body {
  line-height: 1.5;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-family: "UnitedSansRegular-Heavy";
}

@font-face {
  font-family: "UnitedSansRegular-Heavy";
  src: local("UnitedSansRegular-Heavy"),
    url(../../static/media/UnitedSansRgHv.45ab46d7.otf) format("otf"),
    url(../../static/media/UnitedSansRgHv.ee9209db.ttf) format("ttf"),
    url(../../static/media/UnitedSansRgHv.2540eb5f.woff) format("woff");
}

@font-face {
  font-family: "UnitedSansRegular-Medium";
  src: local("UnitedSansRegular-Medium"),
    url(../../static/media/UnitedSansRgMd.bee84640.otf) format("otf"),
    url(../../static/media/UnitedSansRgMd.33a29128.ttf) format("ttf"),
    url(../../static/media/UnitedSansRgMd.6f2387e0.woff) format("woff");
}

@font-face {
  font-family: MaxEmoji;
  src: url(../../static/media/Brand-Live-Emoji.500dc952.otf) format("opentype");
}

